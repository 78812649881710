<template>
    <div class="content_box">
        <div class="header_box">
            <Form label-position="left" inline>
                <div class="just_sb">
                    <div>
                        <FormItem  >
                            <div class="flex">
                                <Input v-model="pageForm.search_text" placeholder="请输入平台账户"></Input>
                                <Select v-model="pageForm.days" clearable style="margin-left:20px;width:300px" placeholder="截止多久到期">
                                    <Option v-for="item in search_type" :value="item.day" :key="item.day">{{ item.text }}</Option>
                                </Select>
                                <Button @click="getList" class="btn-search">搜索</Button>
                            </div>
                        </FormItem>
                    </div>
                    <Button @click="ModalBtn('新增')" class="btn-search"><Icon style="margin-right:5px" type="md-add" />新增</Button>
                </div>
            </Form>
        </div>
        <Table :columns="columns1" :data="data2">
            <template slot-scope="{ index }" slot="id">
                {{index+1}}
            </template>
            <template slot-scope="{ row }" slot="action">
                <Button @click="ModalBtn('编辑',row)" style="color:#2D8CF0;background: unset;" type="text">编辑</Button>
                <Button v-if="row.id!=1" @click="delBtn(row.id)" style="color:red;background: unset;" type="text">删除</Button>
                <Button @click="toJump(row)" type="info">进入平台</Button>
            </template>
        </Table>
        <br />
        <Page :total="total" :current="pageForm.page" @on-change="pageChange" show-total show-elevator style="text-align:right" />

        <Modal @on-cancel="cancel" v-model="modal" width="500" :mask-closable="false" >
            <p slot="header">
                <span>{{modalTitle}}</span>
            </p>
            <div>
                <Form label-position="left" :label-width="100">
                    <FormItem label="平台名称：">
                        <Input placeholder="请输入平台名称" v-model="formData.account_name"></Input>
                    </FormItem>
                    <FormItem label="手机号：">
                        <Input placeholder="请输入手机号" v-model="formData.phone"></Input>
                    </FormItem>
                    <FormItem label="登陆账户：">
                        <Input placeholder="请输入登陆账户" v-model="formData.nickname"></Input>
                    </FormItem>
                    <FormItem label="密码：">
                        <Input placeholder="请输入登陆密码" v-model="formData.password"></Input>
                    </FormItem>

                    <FormItem label="到期时间：">
                        <DatePicker type="date" :options="options3"  format="yyyy-MM-dd"  @on-change="changeDate"  v-model="formData.enddate" placeholder="选择到期时间" style="width: 200px"></DatePicker>
                    </FormItem>

                    <FormItem label="状态：">
                        <RadioGroup v-model="formData.status">
                            <Radio label="1">
                                <span>启用</span>
                            </Radio>
                            <Radio label="2">
                                <span>禁用</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem label="权限：">
                        <CheckboxGroup v-model="formData.power">
                            <Checkbox v-for="(item,index) in power" :key="index" :label="item.id">
                                <span>{{item.name}}</span>
                            </Checkbox>
                        </CheckboxGroup>
                    </FormItem>

                    <Divider orientation="left">独立配置</Divider>
                    <FormItem label="域名：">
                        <Input placeholder="请输入域名,例：www.baidu.com" v-model="formData.domain"></Input>
                    </FormItem>
                    <FormItem label="背景图：">
                        <div class="upload_img_box">
                            <Upload
                                :default-file-list="defalut_imgs"
                                ref="upload"
                                :format="['jpg','jpeg','png']"
                                :max-size="2048"
                                :headers="header"
                                :on-remove="hanlderemove"
                                :on-format-error="handleFormatError"
                                :on-success="uploadImgSuccess"
                                :on-progress="handleProgress"
                                :on-preview="handlePreview"
                                :before-upload="handleBefore"
                                :action="baseURL">
                                    <div class="upload_img">
                                        <div>
                                            <Icon size="40" color="#999" type="md-add" />
                                            <div class="upload_img_txt">（点击上传）</div>
                                            <div style="margin-top:-15px" class="upload_img_txt">2237*1342</div>
                                        </div>
                                    </div>
                            </Upload>
                        </div>
                    </FormItem>
                    <FormItem label="网站logo：">
                        <div class="upload_img_box">
                            <Upload
                                    :default-file-list="defalut_imgs_ico"
                                    ref="upload"
                                    :format="['jpg','jpeg','png']"
                                    :max-size="2048"
                                    :headers="header"
                                    :on-remove="hanlderemove_ico"
                                    :on-format-error="handleFormatError"
                                    :on-success="uploadImgSuccess_ico"
                                    :on-progress="handleProgress"
                                    :on-preview="handlePreview_ico"
                                    :before-upload="handleBefore_ico"
                                    :action="baseURL">
                                <div class="upload_img">
                                    <div>
                                        <Icon size="40" color="#999" type="md-add" />
                                        <div class="upload_img_txt">（点击上传）</div>
                                        <div style="margin-top:-15px" class="upload_img_txt">60*60</div>
                                    </div>
                                </div>
                            </Upload>
                        </div>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="cancel">取消</Button>
                <Button @click="subBtn" type="warning">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
    import config from '@/config';
    import {getCookies} from '@/utils/helper'
    import { platList, delImg, addPlat, delPlat } from "@/api/index";
    import moment from "moment"
    export default {
        data(){
            return{
                header:{'pingtai-authori-zation':getCookies('token')},
                baseURL: config.apiUrl + '/pingtai/upload',
                modalTitle:'',
                modal:false,
                total:0,
                power:[
                    {id:'face-pay', name:'当面付'}
                ],
                pageForm:{
                    page:1,
                    limit:30,
                    search_text:"",
                    days:""
                },
                formData:{
                    account_name:'',
                    nickname:"",
                    status:'1',
                    enddate:"",
                    password:"",
                    imgs:"",
                    img_id:0,
                    power:[],
                    domain:"",
                    ico_url:"",
                    ico_id:0
                },
                defalut_imgs:[],
                defalut_imgs_ico:[],
                options3: {
                    disabledDate (date) {
                        return date && date.valueOf() < Date.now() - 86400000;
                    }
                },
                columns1: [
                    {
                        title: '#',
                        slot: 'id',
                        align: 'center'
                    },
                    {
                        title: '平台名称',
                        width:200,
                        key: 'account_name',
                        align: 'center'
                    },
                    {
                        title: '手机号',
                        width:130,
                        key: 'phone',
                        align: 'center'
                    },
                    {
                        title: '平台账户',
                        key: 'nickname',
                        width:130,
                        align: 'center'
                    },
                    {
                        title: '状态',
                        key: 'status',
                        width:100,
                        align: 'center',
                        render:function(h, params){
                            let text = params.row.status == 1 ? "正常":"禁用";
                            let color = params.row.status == 1 ? "#000000":"#ff0000";
                            return h('div',[
                                h('span', {
                                    style:{
                                        'color':color
                                     }
                                }, text)
                            ])
                        }
                    },
                    {
                        title: '到期时间',
                        key: 'enddate',
                        width:200,
                        align: 'center',
                        render:function(h, params){
                            let date = (params.row.enddate !="0000-00-00" && params.row.enddate !="" && params.row.enddate !=null ) ? moment(params.row.enddate).format('YYYY-MM-DD') : "";
                            let color = "#000000";
                            let day_num = "";
                            let day_text = "";
                            if(date != "") {
                                if (new Date(date).valueOf() < (Date.now() + (86400000 * 30))) {
                                    day_num = Math.ceil((new Date(date).valueOf() - Date.now())/86400000)
                                    color = "#FF0000";
                                    day_text = date + " （"+day_num+"天）"
                                }
                            }
                            return h('div',[
                                h('span', {
                                    style:{
                                        color:color
                                    }
                                }, day_text )
                            ])
                        }
                    },
                    {
                        title: '创建时间',
                        key: 'reg_time',
                        width:200,
                        align: 'center'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'left',
                        width:250,
                        fixed: 'right'
                    }
                ],
                data2: [],
                search_type:[
                    {day:'', text:"所有账户"},
                    {day:180, text:"180天内到期"},
                    {day:90, text:"90天内到期"},
                    {day:60, text:"60天内到期"},
                    {day:30, text:"30天内到期"},
                    {day:15, text:"15天内到期"},
                    {day:7, text:"7天内到期"}
                ]
            }
        },
        created(){
            this.getList()
        },
        methods:{
            toJump(row){
                window.open(row.jump_url)
            },
            changeDate(e){
               this.formData.enddate = e
            },
            getList(){
                platList(this.pageForm).then(res=>{
                    this.total = res.data.count
                    this.data2 = res.data.list
                }).catch(err=>{
                    this.total = 0
                    this.data2 = []
                })
            },
            ModalBtn(e,r){
                if (r) {
                    this.formData = r;
                    this.defalut_imgs = []
                    this.defalut_imgs_ico = []
                    if (this.formData.url!="" && this.formData.url!= null) {
                        this.defalut_imgs = [
                            {
                                name:this.formData.account_name,
                                url:this.formData.url
                            }
                        ]
                    }
                    if (this.formData.ico_url!="" && this.formData.ico_url!= null) {
                        this.defalut_imgs_ico = [
                            {
                                name:this.formData.account_name,
                                url:this.formData.ico_url
                            }
                        ]
                    }
                    if (this.formData.power != "") {
                        this.formData.power = this.formData.power.split(",")
                    } else {
                        this.formData.power = []
                    }
                    this.formData.status = String(this.formData.status)
                    this.formData.enddate =  this.formData.enddate == "0000-00-00" ? "" : this.formData.enddate
                } else {
                    this.formData = {
                        account_name:'',
                        nickname:"",
                        status:'1',
                        enddate:"",
                        password:"",
                        power:[],
                        url:"",
                        img_id:0,
                        domain:"",
                        ico_url:"",
                        ico_id:""
                    }
                }
                this.modalTitle = e
                this.modal = true
            },
            cancel(){
                this.modal = false
            },
            delBtn(id){
                this.$Modal.confirm({
                    title: '提示',
                    content: `您正在使用删除功能`,
                    onOk: () => {
                        delPlat({id:id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                    onCancel: () => {
                    }
                });
            },
            pageChange(e){
                this.pageForm.page = e
                this.getList()
            },
            subBtn(){
                this.formData.enddate = (this.formData.enddate !="0000-00-00" && this.formData.enddate !="" && this.formData.enddate !=null ) ? moment(this.formData.enddate).format('YYYY-MM-DD') : "";
                addPlat(this.formData).then(res=>{
                    this.$Message.success(res.msg)
                    this.getList()
                    this.modal = false
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            //文件上传类型错误
            handleFormatError () {
                this.$Message.warning('暂不支持上传此格式');
            },
            //编辑图片上传图片成功
            uploadImgSuccess(e){
                this.formData.url = e.data.url
                this.formData.img_id = e.data.id
                this.$Message.destroy()
                this.$Message.success(e.msg);
            },
            // 删除图片
            hanlderemove(){
                delImg({id:this.formData.img_id, type:1}).then(res=>{
                    this.formData.url = ""
                    this.formData.img_id = 0
                    this.$Message.success(res.msg);
                }).catch(err=>{
                    this.$Message.error(res.msg);
                })
            },
            handlePreview(){
                this.$Modal.confirm({
                    title: '预览',
                    content: '<img style="width:500px;height:500px" src="'+this.formData.url+'">',
                    width:600
                });
            },
            handleBefore(){
                if(this.formData.img_id != "" && this.formData.img_id != 0) {
                    this.$Message.error("请先删除图片，再进行上传");
                    return false
                }
            },
            // 文件上传时
            handleProgress(){
                this.$Message.loading({
                    content: '上传中...',
                    duration: 0
                });
            },
            //编辑图片上传图片成功
            uploadImgSuccess_ico(e){
                this.formData.ico_url = e.data.url
                this.formData.ico_id = e.data.id
                this.$Message.destroy()
                this.$Message.success(e.msg);
            },
            // 删除图片
            hanlderemove_ico(){
                delImg({id:this.formData.ico_id, type:2}).then(res=>{
                    this.formData.ico_url = ""
                    this.formData.ico_id = 0
                    this.$Message.success(res.msg);
                }).catch(err=>{
                    this.$Message.error(err);
                })
            },
            handlePreview_ico(){
                this.$Modal.confirm({
                    title: '预览',
                    content: '<img style="width:500px;height:500px" src="'+this.formData.ico_url+'">',
                    width:600
                });
            },
            handleBefore_ico(){
                if(this.formData.ico_id != "" && this.formData.ico_id != 0) {
                    this.$Message.error("请先删除图片，再进行上传");
                    return false
                }
            }
        }
    }
</script>

<style scoped>
    .content_box{
        padding: 20px 40px;
    }
    .header_box{
        margin-bottom: 20px;
    }
    .flex{
        display: flex;
    }
    .btn-search {
        width: 80px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFD878;
        border-radius: 4px;
        color: white;
        margin-left: 20px;
        cursor: pointer;
    }
    .just_sb{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .upload_img_box{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .closeBtn{
        position: absolute;
        top: 0;
        right: 0;
    }
    .imgBox{
        position: relative;
        margin: 0 10px 10px 0;
        cursor: pointer;
        width: 100px;
        height: 100px;
        border: 1px solid #DCDEE2;
        border-radius: 10px;
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
    }
    .imgBox img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .upload_img{
        margin-top: -8px;
        cursor: pointer;
        width: 100px;
        height: 100px;
        border: 1px dashed #DCDEE2;
        border-radius: 10px;
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .upload_img_txt{
        margin-top: -10px;
        font-size: 12px;
        color: #999;
    }
</style>