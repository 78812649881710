import { render, staticRenderFns } from "./customerlist.vue?vue&type=template&id=94573d0e&scoped=true&"
import script from "./customerlist.vue?vue&type=script&lang=js&"
export * from "./customerlist.vue?vue&type=script&lang=js&"
import style0 from "./customerlist.vue?vue&type=style&index=0&id=94573d0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94573d0e",
  null
  
)

export default component.exports